import React from "react";

import Layout from "/src/components/Layout";

import Posts from "/src/components/Blog/Posts";

import './tags.scss'


// Components
import { Link, graphql } from "gatsby";


const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  // console.log(tag);
  // console.log(data);
  const { edges, totalCount } = data.allMarkdownRemarkPosts;

  const tagHeader = `${totalCount} post${totalCount === 1 ? "" : "s"
    } tagged with "${tag}"`;

  return (
    <Layout>
      <div className="post__container">

        <h1>{tagHeader}</h1>

        <Posts posts={edges} />


        <Link className="Searchabletag-Link" to="/tags">
          See All tags
        </Link>

      </div>
    </Layout>
  );
};
export default Tags;

export const pageQuery = graphql`
query ($tag: String) {
  allMarkdownRemarkPosts: allMarkdownRemark(
    limit: 2000
    sort: { fields: frontmatter___date, order: DESC }
    filter: { frontmatter: { tags: { in: [$tag] } } }
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          title
          date
          readtime
          tags
        }
        fields {
          slug
        }
        excerpt
      }
    }
  }
  allMarkdownRemarkTags: allMarkdownRemark(limit: 2000) {
    group(field: frontmatter___tags) {
      tag: fieldValue
      totalCount
    }
  }
}
`;

/* <ul>
          {edges.map(({ node }) => {
            const { slug } = node.fields;
            const { title } = node.frontmatter;

            return (
              <li key={slug}>
                <Link to={`/blog/${slug}`}>{title}</Link>
              </li>
            );
          })}
        </ul> */
