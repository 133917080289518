import React from "react";
import { Link } from "gatsby";
import Pagination from './Pagination'
import './Posts.scss'

const Posts = ({ posts, numPages }) => {
  return (
    <div className="Posts">
      {posts.map((edge) => {
        //we know post contain date and we also know my project dont contain date to
        //project will be filter out by this condition
        if (edge.node.frontmatter.date) {
          return (
            <Link
              key={edge.node.fields.slug}
              className="post"
              to={`/blog/${edge.node.fields.slug}`}
            >
              {/* <div className="author__info">
                <img src={pp} alt="" className="author__info--img" />
                <div className="author__info--about">
                  <span className="author__info--about1">Nabin Bhandari</span>
                  <span className="author__info--about2">
                    Full Stack Web Developer
                  </span>
                </div>
              </div> */}
              <h2 className="post_heading">{edge.node.frontmatter.title}</h2>
              <div className="timestamp">
                <span className="timestamp__date">
                  <span className="posticon" role="img" aria-label="calender post">
                    📅
                  </span>
                  {edge.node.frontmatter.date}
                </span>

                <span className="timestamp__read">
                  <span className="posticon" role="img" aria-label="calender watch post">
                    ⌚
                  </span>
                  {edge.node.frontmatter.readtime}
                </span>
              </div>
              <p>{edge.node.excerpt}</p>
              <div className="post__info">
                <div className="tags">
                  <span>
                  Tags:
                  </span>
                  {edge.node.frontmatter.tags.map((tag) => (
                    <span key={tag}>{tag}</span>
                  ))}
                </div>
                <div className="readmore">
                  Read more &nbsp;
                  <span role="img" aria-label="Readmore">
                    🚀
                  </span>
                </div>
              </div>
            </Link>
          );
        } else {
          return undefined;
        }
      })}
      <Pagination numPages={numPages} />
    </div>
  );
};

export default Posts;

// import React from "react";
// import { graphql, Link, useStaticQuery } from "gatsby";

// import Tags from "./Tags";

// import pp from "/src/images/icon.png";

// import "./Post.scss";

// const Post = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       allMarkdownRemark {
//         edges {
//           node {
//             frontmatter {
//               title
//               date
//               readtime
//               tags
//             }
//             fields {
//               slug
//             }
//             excerpt
//           }
//         }
//       }
//       tagsGroup: allMarkdownRemark(limit: 2000) {
//         group(field: frontmatter___tags) {
//           tag: fieldValue
//           totalCount
//         }
//       }
//     }
//   `);

//   return (
//     <div className="post__container">
//       <div className="post__container--1">
//         <ul>
//           {data.allMarkdownRemark.edges.map((edge) => {
//             //we know post contain date and we also know my project dont contain date to
//             //project will be filter out by this condition
//             if (edge.node.frontmatter.date) {
//               return (
//                 <Link
//                   key={edge.node.fields.slug}
//                   className="post"
//                   to={`/blog/${edge.node.fields.slug}`}
//                 >
//                   <div className="author__info">
//                     <img src={pp} alt="" className="author__info--img" />
//                     <div className="author__info--about">
//                       <span className="author__info--about1">
//                         Nabin Bhandari
//                       </span>
//                       <span className="author__info--about2">
//                         Full Stack Web Developer
//                       </span>
//                     </div>
//                   </div>
//                   <h2>{edge.node.frontmatter.title}</h2>
//                   <div className="timestamp">
//                     <span className="timestamp__date">
//                       <span role="img" aria-label="calender post">
//                         📅
//                       </span>
//                       {edge.node.frontmatter.date}
//                     </span>

//                     <span className="timestamp__read">
//                       <span role="img" aria-label="calender watch post">
//                         ⌚
//                       </span>
//                       {edge.node.frontmatter.readtime}
//                     </span>
//                   </div>
//                   <p>{edge.node.excerpt}</p>
//                   <div className="post__info">
//                     <div className="tags">
//                       <div>Tags :</div>
//                       {edge.node.frontmatter.tags.map((tag) => (
//                         <span>{tag}</span>
//                       ))}
//                       {console.log("---")}
//                     </div>
//                     <div className="readmore">
//                       Read more{" "}
//                       <span role="img" aria-label="Readmore">
//                         🚀
//                       </span>
//                     </div>
//                   </div>
//                 </Link>
//               );
//             } else {
//               return undefined;
//             }
//           })}
//         </ul>
//       </div>
//       <Tags item={data.tagsGroup.group} />
//     </div>
//   );
// };

// export default Post;
