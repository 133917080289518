import React, { useState } from "react";
import { Link } from "gatsby";
// import "./Pagination.scss";

const Pagination = ({ numPages }) => {

  const [pointer, setPointer] = useState(numPages)
  const [total, setTotal] = useState(numPages)
  // const [previous, setPrevious] = useState([])

  return (
    <div className="Pagination">
      <Link
        className="Pagination-link"
        onClick={() => {
          setPointer(total--)
        }}
        to={`/blog/ `}
      >
        <i className="bi bi-chevron-left"></i>
      </Link>

      {Array.from({ length: numPages }, (_, i) => (
        <Link
          onClick={() => {
            // if (i === 0) {
            //   i = ''
            // }
            // setPrevious(current)
            // setCurrent(i)
            // if (i <= numPages) {
            //   setNext(current + 1)
            // }

            setPointer(i + 1)

            // console.log(numPages);
            // console.log(i+1);
          }}
          className="Pagination-link"
          key={`pagination-number${i + 1}`}
          to={`/blog/${i === 0 ? "" : i + 1}`}
          activeClassName="activea"
        >
          {i + 1}
        </Link>
      ))}

      <Link
        onClick={() => {
          setPointer(total+1)
        }}
        to={`/blog/${numPages}`}
      >
        <i className="bi bi-chevron-right"></i>
      </Link>
    </div>
  );
};
export default Pagination;